// Set a cookie with the given name, value and options (e.g. expires)
function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    ...options,
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

  for (let optionKey in options) {
    cookie += '; ' + optionKey
    let optionValue = options[optionKey]
    if (optionValue !== true) {
      cookie += '=' + optionValue
    }
  }

  document.cookie = cookie
}

// Get the value of a cookie with the given name
function getCookie(name) {
  let cookieMatch = document.cookie.match(
    new RegExp('(^| )' + name + '=([^;]+)')
  )
  if (cookieMatch) {
    return decodeURIComponent(cookieMatch[2])
  }
  return null
}

export { setCookie, getCookie }
