export const programFriday = [
  {
    tid: 'Ca. 13.45',
    program:
      '(For veldig spesielt interesserte) Tina&Ole kommer ut fra rådhuset etter vielse.',
  },
  {
    tid: '14.00-16.00',
    program: 'Skål og en liten feiring på Last Train.',
  },
]

export const programSaturday = [
  {
    tid: '17.00',
    program: 'Revolver bakgård åpner dørene.',
  },
  {
    tid: '17.30',
    program: 'Ankomst Tina&Ole.',
  },
  {
    tid: '18.00-20.00',
    program: 'Snitt, snitter og taler. Det er beregna ca 4 snitter pr pers.',
  },
  {
    tid: '22.00',
    program: 'Kaker.',
  },
  {
    tid: '23:00',
    program:
      'Flytter vi festen inn og ned i kjelleren til Revolver, men man kan fortsette å henge ute så mye man vil. Ingen alkohol ute i bakgården fra 23:30.',
  },
  {
    tid: 'Seint',
    program: 'Det blir også noe veldig enkel nattmat.',
  },
]
