import React, { useState, useEffect } from 'react'
import { setCookie, getCookie } from './lib/cookies'

function PasswordProtected({ children }) {
  const [password, setPassword] = useState('')
  const [isAuthorized, setIsAuthorized] = useState(true)

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (password === process.env.REACT_APP_PASSWORD) {
      setIsAuthorized(true)
      setCookie('isAuthorized', true, { expires: 14 }) // set a cookie for 2 weeks
    }
  }

  useEffect(() => {
    const cookieValue = getCookie('isAuthorized')
    if (cookieValue === 'true') {
      setIsAuthorized(true)
    }
  }, [])

  return isAuthorized ? (
    <>{children}</>
  ) : (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center h-screen"
    >
      <div className="p-4 rounded-md shadow-md">
        <label
          htmlFor="password"
          className="block mb-2 font-bold text-gray-700"
        >
          Skriv inn det magiske passordet for å se mer snitt og fernet
        </label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          className="w-full px-3 py-2 leading-tight border rounded appearance-none focus:outline-none focus:shadow-outline"
          required
        />
        <button
          type="submit"
          className="mt-4 px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          Send inn
        </button>
      </div>
    </form>
  )
}

export default PasswordProtected
