import React, { useState } from 'react'
import { motion } from 'framer-motion'

import { programFriday, programSaturday } from './lib/program'

function App() {
  const [imageLoading, setImageLoading] = useState(true)

  const imageLoaded = () => {
    setImageLoading(false)
  }

  return (
    <div className="bg-[#87ae98] absolute top-0 left-0 w-full min-h-screen flex flex-col justify-center items-center px-4">
      <header className="">
        <motion.img
          initial={{ height: '8rem', opacity: 0 }}
          // style={{ height: imageLoading ? "6rem" : "auto" }}
          animate={{
            height: imageLoading ? '8rem' : 'auto',
            opacity: imageLoading ? 0 : 1,
          }}
          transition={
            ({ height: { delay: 2.0, duration: 1.0 } },
            { opacity: { delay: 1.0, duration: 2.0 } })
          }
          onLoad={imageLoaded}
          className="mt-16"
          width="100%"
          alt="illustrasjon av revolver, Anders Kvammen"
          src="/assets/revolver_big.png"
        />
      </header>
      <main className="mb-16">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ repeat: 1, duration: 1 }}
        >
          <h1>TINA ♥ OLE</h1>
        </motion.div>

        <motion.div
          className="min-h-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ opacity: { delay: 2, duration: 2 } }}
        >
          <p>Tina & Oles lille bryllupshelg!</p>
          <p>Fjo! Ferdig planlagt, og her følger mer info!</p>
        </motion.div>
        <div className="w-full flex justify-center flex-col md:flex-row prose">
          <motion.div
            className="my-4 md:mx-8 w-full md:w-1/3"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ opacity: { delay: 3, duration: 2 } }}
          >
            <h2>Fredag 25. august</h2>
            {programFriday.map(({ tid, program, index }) => (
              <div key={index} className="flex mb-4">
                <p className="w-2/5 md:pr-2 underline">{tid}</p>
                <p className="w-3/5 text-left">{program}</p>
              </div>
            ))}
          </motion.div>
          <motion.div
            className="my-4 md:mx-8 w-full md:w-1/3"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ opacity: { delay: 4, duration: 2 } }}
          >
            <h2>
              Lørdag 26. august <br /> Fest!
            </h2>
            {programSaturday.map(({ tid, program, index }) => (
              <div key={tid + index} className="flex mb-4">
                <p className="w-2/5 md:pr-2 underline">{tid}</p>
                <p className="w-3/5 text-left">{program}</p>
              </div>
            ))}
            {/* </TextSection> */}
          </motion.div>
        </div>
        <motion.div className="w-full sm:w-3/4 mx-auto">
          <p>Antrekk: sommerpent / dress</p>
          <p className="mt-4">Ønsker du å bidra med noe artig på festen?</p>
          <p>
            Da kan du kontakte våre fantastiske toastmasters Edvart og Fay:
            Edvgran@gmail.com og Fay@tonsofrock.no
          </p>
          <p className=" mt-4">
            Vi ønsker oss ikke gaver! Ditt deilige fjes og grove historier er
            alt vi krever for denne anledningen. Skulle du ønske å bidra til
            festen eller hvetebrødsdagene, kan det overføres til konto
            1227.14.76386❤️
          </p>
        </motion.div>

        {/* <ContactForm /> */}
      </main>
    </div>
  )
}

export default App
